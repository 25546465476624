import React, { useEffect, useMemo, useState } from "react";
import { graphql, Link } from "gatsby";
import Seo from "../components/seo";
import SanityTexte from "../components/SanityTexte";
// import SanityImage from "../components/SanityImage";
import ScrollerImage from "../components/ScrollerImage";
import Map from "../components/ui/Map/Index";
// import LinkFade from "../components/ui/TransitionLinks/LinkFade";
// import { useScroll } from "../hooks/useScroll";
import Scrollspy from "react-scrollspy";
import { useSiteMetadata } from "../hooks/useSiteMetadata";

export const query = graphql`
  query LieuBySlug($slug: String!) {
    sanityLieu(slug: { current: { eq: $slug } }) {
      slug {
        current
      }
      seo {
        metaTitle
        metaDescription
        metaImage {
          asset {
            url
          }
        }
      }
      title
      etage
      surface
      type
      archive
      geoloc
      texte: _rawTexte(resolveReferences: { maxDepth: 10 })
      images: _rawImages(resolveReferences: { maxDepth: 10 })
      imagesEvt: _rawImagesEvt(resolveReferences: { maxDepth: 10 })
      axonometries: _rawAxonometrie(resolveReferences: { maxDepth: 10 })
      fiche {
        asset {
          url
        }
      }
      # axonometrie {
      #   asset {
      #     url
      #   }
      # }
    }
  }
`;

const PageLieu = ({ data }) => {
  // console.log(data)
  const {
    slug,
    seo,
    title,
    etage,
    surface,
    type,
    archive,
    texte,
    images,
    imagesEvt,
    axonometries,
    geoloc,
    fiche,
  } = data.sanityLieu;

  const { settings } = useSiteMetadata();
  // console.log(geoloc);
  const point = [
    {
      slug: slug.current,
      lat: geoloc?.split(",")[0],
      lng: geoloc?.split(",")[1],
      type: type,
      archive: archive,
      image: images && images.length > 0 ? images[0] : "",
    },
  ];

  // const [caption, setCaption] = useState();
  let scrollspySection = ["images"];
  if (imagesEvt) scrollspySection.push("evt");
  if (axonometries) scrollspySection.push("axonometries");
  scrollspySection.push("map");

  return (
    <div className='page'>
      {seo && (
        <Seo
          pageTitle={seo.metaTitle}
          pageDescription={seo.metaDescription}
          pageImage={seo.metaImage?.asset.url}
          template={`template-lieu lieu-${slug.current}`}
          page={true}
        />
      )}

      <div className='grid md:grid-cols-2'>
        <div className='left overflow-hidden-'>
          <section
            id='images'
            className='images-io images'
            data-title='PHOTOS DU LIEU VIDE'>
            {images && <ScrollerImage input={images} showAlt={false} />}
          </section>
          {imagesEvt && (
            <section
              id='evt'
              className='images-io images-evt '
              data-title='ÉVÉNEMENTS PASSÉS'>
              <ScrollerImage input={imagesEvt} showAlt={true} />
            </section>
          )}
          {axonometries && (
            <section
              id='axonometries'
              className='axonometrie'
              data-title='AXONOMÉTRIE'>
              <ScrollerImage input={axonometries} showAlt={false} />
            </section>
          )}
          <section id='map' className='h-screen' data-title='PLAN'>
            <Map input={point} filterable={false} single={true} />
          </section>

          <div className='sticky md:fixed bottom-0 p-sm pb-md- md:p-md scroll-spy text-sm z-10 '>
            <Scrollspy
              items={scrollspySection}
              offset={-100}
              currentClassName='is-current text-accent'>
              <li>
                <a href='#images'>PHOTOS DU LIEU VIDE</a>
              </li>
              {imagesEvt && (
                <li>
                  <a href='#evt'>ÉVÉNEMENTS PASSÉS</a>
                </li>
              )}
              {axonometries && (
                <li>
                  <a href='#axonometries'>AXONOMÉTRIE</a>
                </li>
              )}
              <li>
                <a href='#map'>PLAN</a>
              </li>
            </Scrollspy>
          </div>
        </div>

        <div className='right  md:h-full order-first md:order-last pt-header-height p-sm md:p-md md:py-md-ajusted '>
          <div className='md:sticky md:top-md-ajusted dbg-o-'>
            {/* <div className='sup-header'>
              <LinkFade to='/'>
                <span>← RETOUR AUX LIEUX</span>
              </LinkFade>
            </div> */}
            <div className='header pb-lg uppercase'>
              <div className='text-_lg md:text-lg'>
                <h1 className='text-_lg md:text-lg '>{title}</h1>
                <div className='info'>{etage}</div>
                <div className='info'>{surface}M2</div>
              </div>
            </div>

            <div className='mb-lg'>
              <SanityTexte input={texte} />
            </div>

            <div className='mb-xs'>
              <a
                href={`mailto:${settings.contact}`}
                className='button outline inline-block '>
                Contact
              </a>
            </div>
            {fiche && (
              <div className='mb-lg'>
                <a
                  href={fiche.asset.url}
                  className='button outline inline-block'
                  download>
                  Télécharger le PDF
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageLieu;
