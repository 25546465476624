import React, { useState } from "react";
// import Img from "gatsby-image";

import SanityImage from "./SanityImage";

const ScrollerImage = ({ input, showAlt }) => {
  const [current, setCurrent] = useState(1);
  const [showPagination, setShowPagination] = useState(true);
  // console.log(input);
  const length = input.length;
  // const [ref, inView] = useInView({
  //   threshold: 0.2,
  //   // triggerOnce: true,
  //   // rootMargin: "0px 0",
  // });
  // console.log(length, showPagination);

  // useEffect(() => {
  //   // setShowPagination(inView);
  //   PubSub.publish("IMAGE_CHANGE", {
  //     pagination: `${current + 1}/${length}`,
  //     altText:
  //       showAlt && input[current]
  //         ? input[current]?.asset.altText
  //         : input[current]?.asset.originalFilename,
  //     prefix: !showAlt ? "IMAGE" : "",
  //   });
  // }, [current]);

  return (
    <div className='scroller-image overflow-hidden-'>
      <div className='inner'>
        {input.map((image, i) => (
          <div className='item ' key={i}>
            <SanityImage input={image} showPlaceholder={false} />
            <div className='caption absolute p-md bottom-0 right-0 text-sm z-50 text-white mix-blend-difference'>
              {showAlt ? (
                <>
                  <span>{`${i + 1}/${length}`}</span>
                  {image.asset.altText ? (
                    <span className='pl-lg'>{image.asset.altText}</span>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <span>{`IMAGE ${i + 1}/${length}`}</span>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScrollerImage;
